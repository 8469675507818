0<template>
    <div class="header-top">
      <p class="title">{{ title }}</p>
      <div class="header-top-left">
        <div class="backhome" @click="backIndex">返回首页</div>
        <div class="date">
          <img class="sun" src="@/assets/index/sun.png" />
          <span>{{ temp }}℃ </span>
          <span>{{ text }}</span>
          <!-- <span>{{windDir}}{{windScale}}级</span> -->
        </div>
      </div>
      
      <div class="weather">
        <!-- <span>{{week}}</span> -->
        <span>{{ nowDate }}</span>
      </div>
      <div class="user" @click="toBack()">
        <img src="@/assets/index/user.png" />
        <span>{{ user }}</span>
      </div>
    </div>
</template>
<script>
export default{
   name: 'HeaderTop',
   props:{
     // 标题名称
      title:{ 
        type:String,
        default:''
      }
   },
   data(){
        return{
            weatherKey: "5a0bc17ca9a643dc84fee66d458770ce",
            nowDate: '',
            weathers: [],
            text: "",
            temp: "",
            windDir: "",
            windScale: "",
            user: "进入后台",
            nameScreen: '',
          city:"",
          county:"",
        }
   },
   created(){
    setInterval(() => {
        this.getDate();
      }, 1000)
      this.getTitle()
   },
   methods:{
    backIndex(){
        this.$router.push(window.localStorage.getItem('screenRouter'));
    },
    toBack() {
      this.$router.push("/backHome");
    },
    getTitle(){
      this.$get("/largeScreenManagement/getLargeScreenManagements", {
        page: 1,
        size: 100
      }).then((res) => {
        if(res.data.state == 'success'){
          //this.nameScreen = res.data.datas[0].name;
          this.city = res.data.datas[0].city;
          this.county = res.data.datas[0].county;
          this.requestLookup()

        }
      })
    },
     //获取当前时间
    getDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      month = month < 10 ? "0" + month : month; // 如果只有一位，则前面补零
      let day = date.getDate(); // 日
      day = day < 10 ? "0" + day : day; // 如果只有一位，则前面补零
      let week = date.getDay(); // 星期

      let hour = date.getHours(); // 时
      hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      let minute = date.getMinutes(); // 分
      minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      let second = date.getSeconds(); // 秒
      second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      this.nowDate = `${year}-${month}-${day} ${hour}:${minute}:${second}  `;
    },
     // 天气
     requestLookup() {
      this.$axios({
        method: "GET",
        url: "https://geoapi.qweather.com/v2/city/lookup",
        params: { adm: this.city, location: this.county, key: this.weatherKey },
      }).then((res) => {
        if (res.status == 200) {
          let { location } = res.data;
          this.requestweather7d(location);
        }
      });
    },
     // 实时天气
    requestweather7d(location) {
      this.$axios({
        method: "GET",
        url: "https://devapi.qweather.com/v7/weather/now",
        params: { location: location[0].id, key: this.weatherKey },
      }).then((res) => {
        if (res.status == 200) {
          this.weathers = res.data.now;
          this.text = res.data.now.text;
          this.temp = res.data.now.temp;
          this.windDir = res.data.now.windDir;
          this.windScale = res.data.now.windScale;
        }
      });
    },
   }
}
</script>
<style lang="scss" scoped>
.header-top{
    position: relative;
    z-index: 1;
    color: #c3fffc;
    display: flex;
    justify-content: space-between;
    padding: 0px 5%;
    align-items: center;
    height: 85px;
    font-size: 16px;
    .title{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 40px;
        background: linear-gradient(to bottom, #fff 50%, #b8f9fd 50%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Title";
        letter-spacing: 4px;
        font-style: italic;
    }
    &-left{
      width: 15%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .backhome{
        cursor: pointer;
      }
    }
    .date{
        .sun{
            vertical-align: bottom;
            margin-right: 5px;
          height:24px;
          width:24px;
        }
    }
    .user{
        img{
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    .weather{
        position: absolute;
        right: 23%
    }
}
</style>